@font-face {
  font-family  : 'Bender';
  src          : url("https://lunchmeat.zone/fonts/Bender-Black.woff") format("woff"),
                 url("https://lunchmeat.zone/fonts/Bender-Black.woff2") format("woff2");
  font-weight  : 900;
  font-style   : normal;
  font-display : swap;
}

$annotation-font-size            : 0.8em;
$annotation-line-height          : 1.12em;

$body-text-color                 : #fefeff;
$body-font-size                  : 1.0em;
$body-line-height                : 1.4em;

$link-text-color                 : #bbb89e;

$primary-background-color        : #2d2d2f;

$secondary-background-color      : #535355;

$setting-padding                 : 0.2em;
$setting-margin                  : 0.2em;

$setting-text-color              : #fefeff;
$setting-text-horizontal-padding : 0.45em;
$setting-text-vertical-padding   : 0.22em;

$title-text-color                : #fefeff;

body {
  position                 : absolute;
  top                      : 0;
  right                    : 0;
  bottom                   : 0;
  left                     : 0;
  margin                   : 0;
  padding                  : 0;
  background               : $primary-background-color;
  color                    : $body-text-color;
  font-family              : Arial, sans-serif;
  font-size                : 1.0em;
  line-height              : $body-line-height;
  -webkit-text-size-adjust : none;
}

.bm-burger-button {
  position                 : relative;
  float                    : left;
  width                    : 1.92em;
  height                   : 1.6em;
  left                     : 0.8em;
  top                      : -0.1em;
}

.bm-burger-bars {
  background               : $link-text-color;
}

.bm-burger-bars-hover {
  background               : $link-text-color;
}

.bm-cross-button {
  height                   : 24px;
  width                    : 24px;
}

.bm-cross {
  background               : $link-text-color;
}

.bm-menu-wrap {
  position                 : fixed;
  top                      : 0;
  left                     : 0;
  height                   : 100%;
  transition-duration      : 0.25s !important;
}

.bm-menu {
  background               : #171719;
  padding                  : 1.4em 0.8em 0 0.2em;
  font-family              : 'Bender', sans-serif;
  font-weight              : 900;
  font-size                : 1.4em;
  overflow                 : hidden !important;
}

.bm-item-list {
  color                    : $link-text-color;
  padding                  : 0.8em;
}

.bm-item {
  display                  : inline-block;
}

.bm-overlay {
  position                 : absolute;
  top                      : 0;
  left                     : 0;
  background               : rgba(0, 0, 0, 0.3);
}

.infoTooltip {
  padding-top              : 0.32em !important;
  padding-right            : 0.57em !important;
  padding-bottom           : 0.42em !important;
  padding-left             : 0.57em !important;
  opacity                  : 1 !important;
  border-radius            : 0 !important;
  text-align               : justify;
  line-height              : 1.38em;
  max-width                : 20.0em;
}

.place-top::before {
  z-index                  : -1 !important;
}

a {
  text-decoration          : none;
  color                    : $link-text-color;
}

a.menuLink {
  margin-bottom            : 1em;
}

button {
  margin                   : $setting-margin;
  padding-top              : $setting-text-vertical-padding;
  padding-right            : $setting-text-horizontal-padding;
  padding-bottom           : $setting-text-vertical-padding;
  padding-left             : $setting-text-horizontal-padding;
  background-color         : $secondary-background-color;
  color                    : $link-text-color;
  border                   : none;
  text-align               : left;
  font-family              : Arial, sans-serif;
  font-size                : 1.0em;
  cursor                   : pointer;
}

canvas {
  border                   : 1px solid $secondary-background-color;
  background-color         : $secondary-background-color;
}

canvas.hidden {
  display                  : none;
}

div.battleground {
  margin-left              : 0.24em;
}

div.checkbox {
  display                  : inline;
  margin-left              : $setting-margin;
  margin-right             : $setting-margin;
  padding-top              : $setting-text-vertical-padding;
  padding-right            : $setting-text-horizontal-padding;
  padding-bottom           : $setting-text-vertical-padding;
  padding-left             : $setting-text-horizontal-padding;
  background-color         : $secondary-background-color;
  color                    : $link-text-color;
}

div.credits {
  float                    : right;
  width                    : 200px;
  padding-right            : 0;
  margin-right             : 0;
}

div.fixedEndLocations {
  display                  : table;
  margin                   : $setting-margin;
  padding-top              : $setting-text-vertical-padding;
  padding-right            : $setting-text-horizontal-padding;
  padding-bottom           : $setting-text-vertical-padding;
  padding-left             : $setting-text-horizontal-padding;
  color                    : $setting-text-color;
  font-family              : Arial, sans-serif;
  font-size                : 1.0em;
}

div.information {
  display                  : table;
  margin-left              : $setting-margin + 0.02em;
  margin-bottom            : $setting-text-vertical-padding;
  padding-top              : $setting-text-vertical-padding;
  padding-right            : $setting-text-horizontal-padding;
  padding-bottom           : $setting-text-vertical-padding;
  padding-left             : $setting-text-horizontal-padding;
  background-color         : $secondary-background-color;
  color                    : $setting-text-color;
  font-family              : Arial, sans-serif;
  font-size                : 1.0em;
}

div.informationGrid {
  display                  : inline-grid;
}

div.interactionInformation {
  display                  : table;
  float                    : left;
  padding-top              : $setting-text-vertical-padding;
  padding-right            : $setting-text-horizontal-padding;
  padding-bottom           : $setting-text-vertical-padding;
  padding-left             : $setting-text-horizontal-padding;
  background-color         : $secondary-background-color;
  color                    : $setting-text-color;
  font-family              : Arial, sans-serif;
  font-size                : 1.0em;
}

div.loadingNotification {
  display                  : inline;
  float                    : right;
  padding-top              : $setting-text-vertical-padding;
  padding-right            : $setting-text-horizontal-padding;
  padding-bottom           : $setting-text-vertical-padding;
  padding-left             : $setting-text-horizontal-padding;
  color                    : $setting-text-color;
  font-family              : Arial, sans-serif;
  font-size                : 1.0em;
}

div.measurement {
  display                  : inline-block;
  position                 : absolute;
  visibility               : hidden;
}

div.minimap {
  float                    : right;
  padding-top              : 0;
  padding-right            : 1.2em;
  padding-bottom           : 0;
  padding-left             : 0;
}

div.minimapSpacer {
  width                    : 1.0em;
  height                   : 1.2em;
  padding                  : 0;
  margin                   : 0;
}

div.mode {
  display                  : flex;
  align-items              : center;
  padding-top              : 0.2em;
  padding-left             : 0.2em;
}

div.select {
  display                  : flex;
}

div.set {
  margin-left              : 0.22em;
}

div.settings {
  float                    : left;
  padding-top              : 2.4em;
  padding-right            : 0;
  padding-bottom           : 1.2em;
  padding-left             : 1.2em;
}

div.subtitle {
  color                    : $title-text-color;
  font-family              : Arial, sans-serif;
  text-align               : right;
  font-size                : 0.85 * $body-font-size;
  line-height              : 0.85 * $body-line-height;
  margin-top               : -1.5 * $body-line-height;
}

div.title {
  color                    : $title-text-color;
  font-family              : 'Bender', sans-serif;
  font-weight              : 900;
  font-size                : 2.8 * $body-font-size;
  line-height              : 1.2 * $body-font-size;
  margin-top               : -0.35em;
  margin-bottom            : 0.45em;
  margin-left              : 1.16em;
}

div.titles {
  margin-right             : 1.2em;
}

div.tooltip {
  position                 : fixed;
  display                  : table;
  padding-top              : $setting-text-vertical-padding;
  padding-right            : $setting-text-horizontal-padding;
  padding-bottom           : $setting-text-vertical-padding;
  padding-left             : $setting-text-horizontal-padding;
  background-color         : $secondary-background-color;
  border                   : 1px solid $primary-background-color;
  color                    : $setting-text-color;
  font-family              : Arial, sans-serif;
  font-size                : 1.0em;
  white-space              : pre-wrap;
}

figure {
  text-align               : center;
  font-size                : 0.85 * $body-font-size;
  line-height              : 0.85 * $body-line-height;
}

img.menuIcon {
  float                    : left;
  width                    : 1.0em;
  height                   : 1.0em;
  margin-top               : 0.44em;
  margin-right             : 0.5em;
  vertical-align           : top;
  border                   : 1px solid $secondary-background-color;
}

input {
  margin                   : $setting-margin;
  padding                  : $setting-padding;
  background-color         : $secondary-background-color;
  color                    : $setting-text-color;
  font-family              : Arial, sans-serif;
  font-size                : $body-font-size;
}

input[type="checkbox"] {
  margin-top               : 0;
  margin-right             : 2 * $setting-margin;
  margin-bottom            : 0;
  margin-left              : 0;
  padding                  : 0;
}

input[type="number"] {
  width                    : 3.2em;
}

input[type="text"] {
  width                    : 3.2em;
}

input.geometric {
  width                    : 4.0em;
}

label.checkbox {
  cursor                   : pointer;
  user-select              : none;
}

select {
  margin                   : $setting-margin;
  padding-top              : $setting-text-vertical-padding;
  padding-right            : $setting-padding;
  padding-bottom           : $setting-text-vertical-padding;
  padding-left             : $setting-padding;
  background-color         : $secondary-background-color;
  color                    : $setting-text-color;
  border                   : none;
  font-family              : Arial, sans-serif;
  font-size                : $body-font-size;
}

select.mode {
  margin-left              : 0.22em;
}

span.info {
  margin                   : 0;
  padding                  : 0;
  background-color         : transparent;
  color                    : $link-text-color;
  font-size                : 1.4em;
  cursor                   : pointer;
}

span.spacer {
  visibility               : hidden;
}

table {
  margin-left              : 0;
  border-collapse          : collapse;
  border-spacing           : 0;
}

table.comebackArena {
  margin-left              : 0.68em;
}

table.phases {
  margin-left              : 0.68em;
}

td {
  padding-left             : 0;
  padding-right            : 0;
}

td.comebackArena {
  padding-left             : 0.2em;
}

td.header {
  padding-right            : 0.1em;
}

td.phases {
  padding-left             : 0.2em;
}

td.annotation {
  color                    : $setting-text-color;
  font-family              : Arial, sans-serif;
  font-size                : $annotation-font-size;
  line-height              : $annotation-line-height;
  text-align               : center;
  white-space              : pre-wrap;
}

tr.oneLineAnnotation {
  height                   : $annotation-line-height;
}

tr.threeLineAnnotation {
  height                   : $annotation-font-size + 2 * $annotation-line-height;
}

tr.fiveLineAnnotation {
  height                   : $annotation-font-size + 4 * $annotation-line-height;
}

#emMeasurement {
  width                    : 1em;
  height                   : 1em;
}
